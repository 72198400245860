import { PaletteOptions } from '@mui/material/styles'
import { SimplePaletteColorOptions } from '@mui/material'

interface ExtendedColorOptions extends SimplePaletteColorOptions {
  100?: string
  200?: string
  300?: string
  400?: string
  500?: string
  600?: string
  800?: string
  900?: string
  950?: string
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: ExtendedColorOptions
    green: ExtendedColorOptions
    red: ExtendedColorOptions
    yellow: ExtendedColorOptions
    pink: ExtendedColorOptions
    gray: ExtendedColorOptions
  }

  interface TypeBackground {
    secondary: string
    backdrop: string
    modalBorder: string
    input: string
  }

  interface PaletteOptions {
    tertiary: ExtendedColorOptions
    green: ExtendedColorOptions
    red: ExtendedColorOptions
    yellow: ExtendedColorOptions
    pink: ExtendedColorOptions
    gray: ExtendedColorOptions
  }

  interface TypographyVariants {
    body2Medium: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body2Medium?: React.CSSProperties
  }

  interface TypeText {
    tertiary: string
  }
}

const primaryLight = {
  main: '#2E60DC',
  100: '#F2F5FD',
  400: '#819FEA',
  600: '#18398C',
  950: '#0B1B41',
  200: '#D3DEF8',
  800: '#18398C',
}

export const palettePrimary = {
  light: primaryLight,
  dark: {
    ...primaryLight,
    100: primaryLight[950],
    200: primaryLight[800],
    800: primaryLight[200],
    950: primaryLight[200],
  },
}

const secondaryLight = {
  main: '#8B30F3',
  800: '#600BC1',
  950: '#240448',
  100: '#F7F1FE',
  200: '#E9D8FD',
  400: '#C495F9',
}

export const paletteSecondary = {
  light: secondaryLight,
  dark: {
    ...secondaryLight,
    main: secondaryLight[100],
    100: '#3E146E',
    200: secondaryLight[800],
    800: secondaryLight[200],
    950: secondaryLight[100],
  },
}

const tertiaryLight = {
  main: '#CEFF2D',
  950: '#0F231A',
  100: '#F4FFD0',
  50: '#FBFFEE',
}

export const paletteTertiary = {
  light: tertiaryLight,
  dark: tertiaryLight,
}

const redLight = {
  main: '#EE6C4D',
  600: '#D13E3B',
  400: '#FFAB99',
  95: '#5C1100',
  200: '#FDECEC',
  100: '#FFF2F0',
  800: '#AE2F13',
}

export const paletteRed = {
  light: redLight,
  dark: {
    ...redLight,
    10: '#611D00',
    100: '#611D00',
    800: redLight[200],
    main: '#FFAC93',
  },
}

const yellowLight = {
  dark: '#A88E0E',
  main: '#2E60DC',
  light: '#F2F5FD',
  button: '#FFEB84',
  lightDark: '#f6f4c8',
}

export const paletteYellow = {
  light: yellowLight,
  dark: yellowLight,
}

const greenLight = {
  dark: '#002C0E',
  main: '#A373F1',
  light: '#F3ECFD',
}

export const paletteGreen = {
  light: greenLight,
  dark: greenLight,
}

const pinkLight = {
  main: '#ED78ED',
  800: '#901490',
  100: '#FDF2FD',
}

export const palettePink = {
  light: pinkLight,
  dark: pinkLight,
}

const greyLight = {
  main: '#EBEBEB',
  dark: '#E9E9E9',
  100: '#FAFAFA',
  150: '#F5F5F5',
  200: '#EBEBEB',
  300: '#E6E6E6',
  500: '#CCCCCC',
  600: '#A6A6A6',
  700: '#7E7E7E',
  800: '#4D4D4D',
  900: '#1F1F1F',
}

export const paletteGrey = {
  light: greyLight,
  dark: {
    ...greyLight,
    100: greyLight[900],
    150: greyLight[900],
    200: greyLight[800],
    500: greyLight[800],
    700: greyLight[500],
    800: greyLight[100],
  },
}

export const paletteBackground = {
  light: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
    backdrop: 'rgba(250, 250, 250, 0.5)',
    modalBorder: 'none',
    secondary: '#FFFFFF',
    input: paletteGrey.light[100],
  },
  dark: {
    default: '#111111',
    paper: '#111111',
    backdrop: 'rgba(0, 0, 0, 0.5)',
    modalBorder: `1px solid ${paletteGrey.dark[200]}`,
    secondary: '#1F1F1F',
    input: paletteGrey.dark[100],
  },
}

export const palette: PaletteOptions = {
  text: {
    secondary: 'rgba(0,0,0,0.6)',
    tertiary: paletteGrey.light[600],
  },
  background: paletteBackground.light,
  primary: palettePrimary.light,
  secondary: paletteSecondary.light,
  tertiary: paletteTertiary.light,
  green: paletteGreen.light,
  red: paletteRed.light,
  yellow: paletteYellow.light,
  pink: palettePink.light,
  gray: paletteGrey.light,
  grey: paletteGrey.light,
  action: {
    disabled: 'rgba(0, 0, 0, 0.5)',
  },
  divider: greyLight[200],
}

export const darkPalette: PaletteOptions = {
  ...palette,
  mode: 'dark',
  text: {
    primary: '#ffffff',
    secondary: '#F5F5F5',
    tertiary: paletteGrey.dark[600],
  },
  background: paletteBackground.dark,
  red: paletteRed.dark,
  grey: paletteGrey.dark,
  gray: paletteGrey.dark,
  primary: palettePrimary.dark,
  secondary: paletteSecondary.dark,
  tertiary: paletteTertiary.dark,
  divider: paletteGrey.dark[200],
}