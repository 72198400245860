import {
  Components,
  createTheme,
  PaletteOptions,
  Theme,
} from '@mui/material/styles'
import { darkPalette, palette } from './colors'
import { typography } from './typography'
import { breakpoints, shadows } from './values'
import '@mui/material/styles/createPalette'

declare module '@mui/material/styles' {
  interface Theme {
    extras: {
      name: string
      darkMode: boolean
      overrideAll: boolean
    }
  }

  // allow configuration using `createTheme()`
  interface ThemeOptions {
    extras?: {
      name?: string
      darkMode?: boolean
      overrideAll?: boolean
    }
  }
}

export const getComponents = (
  palette: PaletteOptions,
): Components<Omit<Theme, 'components'>> => ({
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: 16,
        '&:not(:last-child)': {
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      sizeLarge: {
        height: 48,
      },
    },
    defaultProps: {
      color: 'inherit',
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: palette.divider,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.MuiInputBase-root': {
          backgroundColor: palette.background?.input,
        },
      },
    },
  },
})

export const shape = {
  borderRadius: 8,
}

export const lightTheme = createTheme({
  extras: {
    name: 'light',
    darkMode: false,
  },
  shape,
  palette: palette,
  typography: typography,
  components: getComponents(palette),
  breakpoints: breakpoints,
  shadows: shadows,
})

export const darkTheme = createTheme({
  extras: {
    name: 'dark',
    darkMode: true,
  },
  shape,
  palette: darkPalette,
  typography: typography,
  components: {
    ...getComponents(darkPalette),
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: darkPalette.text.primary,
          },
        },
      },
    },
  },
  breakpoints: breakpoints,
  shadows: shadows,
})