import React, { useEffect, useState } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import Link from 'next/link'

import mixpanel from 'mixpanel-browser'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

export default function CookieModal({ isIndex, onAccept }) {
  const [displayPopUp, setDisplayPopUp] = useState(false)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMobileApp = isIndex
    ? false
    : useSelector((state) => state.user.isMobileApp)
  const router = useRouter()
  const isMobileAppParam = router.query.isMobileApp === 'true'

  useEffect(() => {
    const seenPopUp =
      localStorage.getItem('cookies') === 'true' ||
      localStorage.getItem('cookies') === 'false'
    setDisplayPopUp(!seenPopUp)
  }, [])

  const handleAcceptCookies = () => {
    mixpanel.opt_in_tracking()
    localStorage.setItem('cookies', 'true')
    window.dispatchEvent(new Event('storage'))
    setDisplayPopUp(false)
    onAccept()
  }

  const handleRejectCookies = () => {
    localStorage.setItem('cookies', 'false')
    window.dispatchEvent(new Event('storage'))
    setDisplayPopUp(false)
  }

  return (
    <div>
      {displayPopUp && !isMobileApp && !isMobileAppParam && (
        <Modal
          style={{ zIndex: 99992 }}
          open={true}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            sx={(theme) => ({
              position: 'fixed',
              bottom: '0',
              width: '100%',
              bgcolor: 'background.paper',
              p: 3,
              display: 'flex',
              justifyContent: 'center',
              pb: 4,
              color: theme.palette.mode === 'dark' ? '#FAFAFA' : '#4D4D4D',
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'stretch', md: 'center' },
                maxWidth: theme.breakpoints.values.xl,
                width: '100%',
                gap: theme.spacing(3),
                margin: '0 auto',
                px: theme.spacing(2),
              })}
            >
              <Typography
                variant='caption'
                id='modal-modal-description'
                style={{
                  lineHeight: '14.32px',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                BASH uses functional, analytical, cookies to improve the quality
                and effectiveness of our website. With your consent we also use
                these cookies for other purposes. Read our{' '}
                <strong>
                  <Link href='/cookienotice'>Cookie Notice</Link>
                </strong>{' '}
                and{' '}
                <strong>
                  <Link href='/privacy'>Privacy Policy</Link>
                </strong>
                .
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  flexShrink: 0,
                  gap: 1.5,
                }}
              >
                <BashButton
                  type={BashButtonType.PRIMARY_LIGHT_NO_THEME}
                  sx={{
                    height: 32,
                    fontSize: 12,
                    border: 'none',
                  }}
                  onClick={handleRejectCookies}
                  variant='outlined'
                >
                  Accept essential cookies only
                </BashButton>
                <BashButton
                  type={BashButtonType.PRIMARY_NO_THEME}
                  sx={{
                    height: 32,
                    fontSize: 12,
                  }}
                  onClick={handleAcceptCookies}
                >
                  Accept all cookies
                </BashButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  )
}
